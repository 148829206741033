import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Selectors } from 'store/selectors';
import { SidePanel } from 'components/SidePanel/SidePanel';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { useMsal } from '@azure/msal-react';
import { removeFromLocalStorage, setToLocalStorage } from 'shared/utils/localStorage';
import { OIDCUser } from 'OIDCAuth/models/OIDCUser';
import { msalInstance } from 'OIDCAuth/AuthProvider/authProvider';
import Services from 'shared/shared.services';
import GlobalInterceptor from './shared/interceptors/global.interceptor';
import { useUserLoggedOutFromAnotherTabListener } from './shared/hooks/useUserLoggedOutFromAnotherTabListener';
import GlobalRequestLoader from './components/GlobalRequestLoader';
import Footer from './components/Layout/Footer/Footer';
import Routes from './Routes';
import Header from './components/Layout/Header/Header';
import PageContentHeader from './components/Layout/PageContentHeader/PageContentHeader';
import { SessionTimeoutModal } from './components/Modals/SessionTimeoutModal';
import CustomHooks from './shared/shared.hooks';

import '@progress/kendo-theme-default/dist/all.css';
import './App.scss';
import './KendoUI-styles.scss';
import './Toastify.scss';

import { reactPlugin } from './appinsights/appInsights';

GlobalInterceptor.init(); // init axios interceptors

const App: FC = () => {
  const { accounts } = useMsal();
  const isAuthenticated = Services.BL.AuthorizationService.isAuthorized();
  const getlientId = msalInstance.getConfiguration().auth.clientId;
  const tokenKeysString = sessionStorage.getItem(`msal.token.keys.${getlientId}`);
  const tokenKey = tokenKeysString && JSON.parse(tokenKeysString).idToken[0];
  const tokenJson = sessionStorage.getItem(tokenKey);
  const token = tokenJson && JSON.parse(tokenJson).secret;
  let userName: any;
  if (accounts.length > 0) {
    userName = accounts[0].username;
  }

  function getOIDCUser() {
    return new OIDCUser(token, userName, token, true);
  }

  useEffect(() => {
    if (isAuthenticated === true) {
      setToLocalStorage('isRefreshToken', 'true');
    } else {
      removeFromLocalStorage('isRefreshToken');
    }
    if (token !== null) {
      Services.BL.AuthorizationService.resolveToken(getOIDCUser());
    }
  }, [isAuthenticated, token]);

  const isErrorLocaleKeys = useSelector(Selectors.Localization.selectIsErrorLocaleKeys);
  const isLoadedLocaleKeys = useSelector(Selectors.Localization.selectIsLoadedLocaleKeys);
  const showPrivateElements = CustomHooks.usePrivateLayout();

  useUserLoggedOutFromAnotherTabListener();

  return isErrorLocaleKeys && isLoadedLocaleKeys ? (
    <div className="serviceUnavailableMessage">Service is temporarily unavailable</div>
  ) : (
    <>
      <SessionTimeoutModal />
      <Header />
      <div className="appWrapper">
        {showPrivateElements && <SidePanel />}
        <div className="appContent">
          <main className="appContent--content">
            <GlobalRequestLoader />
            {showPrivateElements && <PageContentHeader />}
            <Routes />
          </main>
          <Footer />
        </div>
      </div>
      <ToastContainer hideProgressBar autoClose={5000} toastClassName="Deloitte-toaster" icon={false} />
    </>
  );
};

export default withAITracking(reactPlugin, App);
